<template>
  <v-row class="align-center">
    <v-col cols="12">
      <v-row dense class="align-center">
        <v-col
          v-for="question in testData.questions"
          :key="question.id"
          cols="12"
          sm="6"
          color="grey"
        >
          <v-row dense :class="{ 'font-weight-bold': question.important }" class="align-center">
            <v-col cols="10" class="">
              {{ question.text }}
            </v-col>
            <v-col cols="2">
              <v-icon :color="question.answer ? 'success' : 'error'">
                {{ question.answer ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="mt-2">
      <v-card flat outlined>
        <v-card-title class="subtitle-2">Observaciones del test:</v-card-title>
        <v-card-text>{{ testData.comments }}</v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" class="d-flex justify-space-between font-weight-bold">
      <div>Resultado global práctico:</div>
      <v-chip :color="testData.approvedPractical ? 'success' : 'error'" outlined>
        <v-icon class="mr-2">
          {{ testData.approvedPractical ? "mdi-check" : "mdi-close" }}
        </v-icon>
        {{ testData.approvedPractical ? "Aprobado" : "No aprobado" }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    testData: { type: Object, default: () => {} },
  },
};
</script>

<style scoped></style>
